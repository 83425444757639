<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 offset-none col-sm-6 offset-sm-3 col-md-4 offset-md-4">
        <div class="d-flex align-items-center login-form-container">
          <div class="w-100" v-if="token">
            <div class="text-center mb-4">
              <h1 class="login-form-header">Enter your new password</h1>
              <!--              <div class="login-form-text">-->
              <!--                Hello <span class="username-text">{{ username }}</span-->
              <!--                >, this is your first time logging in or you have requested a-->
              <!--                password reset. <br />Please create your new password and then-->
              <!--                login again.-->
              <!--              </div>-->
            </div>
            <b-alert variant="danger" :show="errorMessage != ''">{{
              errorMessage
            }}</b-alert>
            <b-form @submit="onSubmit">
              <b-form-group
                id="input-group-password"
                label="New Password:"
                label-for="input-password"
              >
                <b-form-input
                  id="input-password"
                  v-model="form.password_1"
                  placeholder="Enter password"
                  type="password"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-confirm-password"
                label="Confirm Password:"
                label-for="input-confirm-password"
              >
                <b-form-input
                  id="input-confirm-password"
                  v-model="form.password_2"
                  placeholder="Confirm password"
                  type="password"
                  required
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary" block>Submit</b-button>

              <p class="text-right mt-2">
                <a @click="goBackToLogin"
                  ><u><i>Back to Login</i></u></a
                >
              </p>
            </b-form>
          </div>
          <div v-else-if="!token && !expired_token">
            <h5 class="text-center">
              <strong> Sorry Invalid Token! </strong>
            </h5>
          </div>
          <div v-else-if="expired_token">
            <h5 class="text-center">
              <strong> Sorry Token Expired! </strong>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
let utils = require("@/assets/global/js/utils.js");

export default {
  data() {
    return {
      username: "",
      form: {
        password_1: "",
        password_2: ""
      },
      errorMessage: "",
      token: false,
      expired_token: false
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  mounted: function () {
    if (this.getCurrentUser != null) {
      this.username = this.getCurrentUser.username;
    }
    this.validateResetPassword();
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      var $this = this;
      var form_data = null;
      form_data = {
        ...$this.form,
        password_reset_token: $this.$route.params.token,
        email: $this.$route.params.email
      };
      //var API_URL = $this.getAPIServerURL + "/users/my-user/new-password/";
      var API_URL = $this.getAPIServerURL + "/auth/new-password/";
      const client = $this.$root.getAjaxFetchClient();

      client
        .postRequest(API_URL, form_data)
        .then((data) => {
          if (data && data.detail == undefined) {
            // Reset session before redirect to Login page
            $this.$store.dispatch("session/setAccessTokenType", null);
            $this.$store.dispatch("session/setAccessToken", null);
            $this.$store.dispatch("session/setSecureToken", null);
            $this.$store.dispatch("session/setWSServerKey", null);
            sessionStorage.removeItem("sessionKey");
            sessionStorage.removeItem("wsserverKey");

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Please login again with your new password",
              message_type: "success"
            });
            $this.$router.push({ name: "login" }).catch(() => {});
          } else {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    goBackToLogin: function (e) {
      e.preventDefault();

      // Reset session before redirect to Login page
      this.$store.dispatch("session/setAccessTokenType", null);
      this.$store.dispatch("session/setAccessToken", null);
      this.$store.dispatch("session/setSecureToken", null);
      this.$store.dispatch("session/setWSServerKey", null);
      sessionStorage.removeItem("sessionKey");
      sessionStorage.removeItem("wsserverKey");

      this.$router.push({ name: "login" }).catch(() => {});
    },
    validateResetPassword() {
      var $this = this;
      var form_data = null;
      form_data = {
        password_reset_token: $this.$route.params.token,
        email: $this.$route.params.email
      };
      let API_URL =
        this.getAPIServerURL + "/auth/password-reset-token/validate/";
      const client = this.$root.getAjaxFetchClient();
      console.log("client", client);
      client
        .postRequest(API_URL, form_data)
        .then((data) => {
          if (data.valid_token && data.expired === false) {
            this.token = true;
          } else if (data.valid_token && data.expired) {
            this.expired_token = true;
          } else if (data.valid_token === false) {
            this.token = false;
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    }
  }
};
</script>
<style lang="scss">
@import "./App.scss";
</style>
